import { PlusIcon } from "@heroicons/react/20/solid";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { ErrorMessage, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const THUMBNAIL_SIZE = 200;
function resize(image, maxSize) {
  function doResize(maxSize) {
    const canvas = document.createElement("canvas");

    let width = image.width;
    let height = image.height;

    // Scale down image until largest dimension is below maxSize
    let biggest = Math.max(width, height);
    while (biggest > maxSize) {
      width = width / 2;
      height = height / 2;
      biggest = biggest / 2;
    }

    // Render the image onto the canvas at the desired size
    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d").drawImage(image, 0, 0, width, height);

    // Write out the canvas to a dataURL
    const d = image.src;
    const mime = d.substring(d.indexOf(":") + 1, d.indexOf(";"));
    return canvas.toDataURL(mime);
  }
  return { image: doResize(maxSize), thumbnail: doResize(THUMBNAIL_SIZE) };
}

const loadAndResizeImageFile = (settings) => {
  const file = settings.file;
  const reader = new FileReader();
  const image = new window.Image(); // window.Image to facilitate mocking

  return new Promise(function (ok, no) {
    if (!file.type.match(/image.*/)) {
      no(new Error("Not an image"));
      return;
    }

    // Read the file and pass to resize
    reader.onload = function (readerEvent) {
      image.onload = function () {
        return ok(resize(image, settings.maxSize));
      };
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
};

export const loadAndResizeImageDataURL = (settings) => {
  const image = new window.Image(); // window.Image to facilitate mocking

  return new Promise(function (ok, no) {
    image.onload = function () {
      return ok(resize(image, settings.maxSize));
    };
    image.src = settings.dataURL;
  });
};

export default function ImageInput({
  label,
  name,
  startingValue,
  required,
  maxSize,
  currentLocationRef,
}) {
  const { setFieldValue } = useFormikContext();
  const [images, setImages] = useState(startingValue);

  // Whenever images state changes, sync it to form
  useEffect(() => {
    setFieldValue(name, images);
  }, [images, name, setFieldValue]);

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        <span>
          {label}
          {required ? (
            <span className="text-red-800 text-superscript">*</span>
          ) : null}
        </span>
      </label>
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        {/*Hidden file input field that is used to select files*/}
        <input
          id={name}
          data-testid={name}
          name={name}
          type="file"
          accept="image/*"
          capture="environment"
          className="hidden"
          onChange={(event) => {
            // Save the geolocation now so it doesn't get affected by the
            // resizing operation
            const geolocation = currentLocationRef?.current;
            const timestamp = new Date().toISOString();
            // Read the selected file and push it to images
            const file = event.currentTarget.files[0];

            loadAndResizeImageFile({ file, maxSize }).then(async (result) => {
              const format = result.image.split(";base64,")[0];
              // Extracting the extension (e.g., "png")
              const ext = format.split("/")[1];
              // generate uuid for image_key
              const image_key = `${uuidv4()}.${ext}`;
              setImages((images) => [
                ...images,
                {
                  ...result,
                  geolocation,
                  image_key,
                  timestamp,
                },
              ]);
            });
            // Clear the image so that we always fire onChange for the next one
            event.currentTarget.value = "";
          }}
        />
        <div className="flex flex-wrap gap-4 w-full max-w-lg rounded-lg">
          {images?.map((image, i) => {
            return (
              <div key={i} className="relative h-32 w-32">
                <img
                  className="h-32 w-32 object-cover rounded-lg border border-gray-300"
                  src={image.image || image.thumbnail}
                  alt={`user submitted ${i} ${name}`}
                />
                <div
                  className="hidden"
                  data-testid={`image-geolocation-${name}-${i}`}
                >
                  {JSON.stringify(image.geolocation, null, 2)}
                </div>
                <div className="absolute -top-3 -right-3">
                  <XCircleIcon
                    className="h-6 w-6 fill-white text-gray-600 cursor-pointer"
                    onClick={() =>
                      setImages((images) => {
                        setImages(images.filter((_, j) => i !== j));
                      })
                    }
                  />
                </div>
              </div>
            );
          })}
          {/* Label for the hidden file field
              htmlFor means that when the label is clicked, the file prompt is
              shown even though the file input is hidden*/}
          <label
            className="h-32 w-32 border border-dashed border-gray-400 rounded-lg"
            htmlFor={name}
          >
            <div className="grid place-items-center h-full w-full cursor-pointer">
              <PlusIcon className="h-12 w-12 text-gray-400" />
            </div>
          </label>
        </div>
        <p className={`text-sm font-medium text-red-800 mt-0`}>
          <ErrorMessage name={name} />
        </p>
      </div>
    </div>
  );
}
